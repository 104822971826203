import React from "react";
import Banner from "../../components/Banner/Banner";
import GalleryCom from "../../components/GalleryCom/GalleryCom";

function SingleSpark() {
  const IMAGES = [
    {
      id: 22,
      src: process.env.PUBLIC_URL + "/img/Gallery/spark-2.webp",
      caption: "SPARK",
      size: "200-200",
      class: "one-third",
    },
    {
      id: 23,
      src: process.env.PUBLIC_URL + "/img/Gallery/spark-3.webp",
      caption: "SPARK",
      size: "200-200",
      class: "one-third",
    },
    {
      id: 24,
      src: process.env.PUBLIC_URL + "/img/Gallery/spark-4.webp",
      caption: "SPARK",
      size: "300-300",
      class: "one-third",
    },
    {
      id: 25,
      src: process.env.PUBLIC_URL + "/img/Gallery/spark-5.webp",
      caption: "SPARK",
      size: "300-300",
      class: "one-third",
    },
    {
      id: 26,
      src: process.env.PUBLIC_URL + "/img/Gallery/spark-6.webp",
      caption: "SPARK",
      size: "200-200",
      class: "one-third",
    },
    {
      id: 27,
      src: process.env.PUBLIC_URL + "/img/Gallery/spark-7.webp",
      caption: "SPARK",
      size: "200-200",
      class: "two-third",
    },
    {
      id: 28,
      src: process.env.PUBLIC_URL + "/img/Gallery/spark-8.webp",
      caption: "SPARK",
      size: "200-200",
      class: "one-third",
    },
  ];
  return (
    <div>
      <Banner title={"SPARK"} />
      <div id="performanz-trainer">
        <div class="container">
          <div class="row animate-box fadeInUp animated-fast">
            <div class="col-md-12 col-md-offset-2 text-center performanz-heading">
              <h2>Performanz Spark</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="row">
                <div
                  style={{ marginTop: "-30px" }}
                  class="col-lg-8  animate-box fadeInUp animated-fast"
                >
                  <p></p>
                  <h3>
                    {" "}
                    Görsel ve İnovatif egzersizler sunabilen kablosuz elektronik
                    eğitim seti{" "}
                  </h3>
                  <br />
                  Spark eğitim seti özellikle spor branşlarında yapılan testler
                  ve egzersizler için oluşturulmuş, kullanıcının hayal gücü ile
                  sınırlı sayıda çalışma bulunan bir görsel çalışma setidir.
                  <br />
                  Sporcuların egzersiz veya antrenman yapma, performansını
                  ölçme, testler yapabilme gibi özellikleri bir araya getirir.
                  Fotosel kapıları olarak kullanılabildiği gibi bağımsız olarak
                  da refleks, çabukluk, sürat antrenmanları için de kullanımı
                  mümkündür.
                  <br />
                  Ayrıca sakatlık sonrası tekrar antrenmanları yapılmasını da
                  sağlar. Tamamen üzerinde ki ledlerin antrenman veya
                  egzersizlere görsel sunumlar katarak daha istekli hale
                  getirilmesi için tasarlanmıştır.
                  <br />
                  T.C. Gençlik ve Spor Bakanlığının, Milli Eğitim Bakanlığı ile
                  beraber yürüttüğü “Türkiye Sportif Yetenek Taraması ve Spora
                  Yönlendirme” testlerinde 81 il, 135 noktada Türkiye genelinde
                  3. Ve 4. Sınıfların üzerinde yapılan REFLEKS TESTLERİ için
                  kullanılmaktadır.
                  <p class="mt-5">Uygulandığı Spor Dalları</p>
                  <div className="my_container">
                    <ul>
                      <li>Futbol</li>
                      <li>Basketbol</li>
                      <li>Voleybol</li>
                      <li>Hentbol</li>
                      <li>Fitness</li>
                      <li>Personel Koç</li>
                      <li>Rehabilitasyon</li>
                      <li>Tenis / Badminton</li>
                      <li>Dövüş / Savunma Sporları</li>
                      <li>Atletizm</li>
                      <li>Genel</li>
                    </ul>
                  </div>
                  <br />
                  Spark elektronik antrenman sistemi ışıklı geri beslemeli
                  antrenman sistemleri sınıfında bir egzersiz setidir. Sistemde
                  infrared yakınlık sensörü ve ivme ölçer bulunur. Modüllere
                  tepki verebilmek için uygulanan çalışma senaryosuna göre
                  modülün önünden elini veya kullanılan spor aletini geçirerek
                  veya sporcunun kendisini geçirerek veya modüle dokunarak /
                  vurarak uygulama yapılabilir.
                  <p></p>
                  <p></p>
                  <p>
                    {" "}
                    Sistem özellikleri ve gereksinimler için{" "}
                    <a
                      href="https://performa.nz/docs/Spark_2021.pdf"
                      download=""
                    >
                      {" "}
                      tıklayınız.
                    </a>
                  </p>
                  {/* <p>
                    Uygulamamızı indirmek için
                    <a
                      href="https://performa.nz/files/apk/Spark.2.0.5.apk"
                      download=""
                    >
                      {" "}
                      tıklayınız.
                    </a>
                  </p> */}
                  {/* <p>
                    Uygulamamızı indirmek için(SparkDevAPP)
                    <a
                      href="https://performa.nz/files/apk/spark_dev_app.apk"
                      download=""
                    >
                      {" "}
                      tıklayınız.
                    </a>
                  </p>
                  <p>
                    Uygulamamızı indirmek için(SparkServis)
                    <a
                      href="https://performa.nz/files/apk/sparkservis.apk"
                      download=""
                    >
                      {" "}
                      tıklayınız.
                    </a>
                  </p> */}
                </div>
                <div class="col-lg-4  mb-5  animate-box fadeInUp animated-fast">
                  <img
                    src={process.env.PUBLIC_URL + "/img/Gallery/spark-1.webp"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="performanz-started"
          class="performanz-bg"
          style={{
            backgroundImage: `url("${
              process.env.PUBLIC_URL + "/img/bg_img/img_bg_1.jpg"
            }")`,
          }}
        >
          <div class="overlay"></div>
          <div id="performanz-gallery">
            <div class="container">
              <div class="row">
                <div class="col-md-12 col-md-offset-2 text-center performanz-heading animate-box fadeInUp animated-fast">
                  <h2 style={{ color: "#fff" }}>PERFORMANZ SPARK</h2>
                  <p>ÖLÇTÜĞÜNÜ GELİŞTİRİRSİN!</p>
                </div>
              </div>
            </div>

            <div class="container-fluid">
              <div class="row row-bottom-padded-md">
                <div class="col-md-12 image-popup">
                  <GalleryCom images={IMAGES} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleSpark;
