import React from "react";
import GalleryCom from "../../components/GalleryCom/GalleryCom";
import Banner from "../../components/Banner/Banner";
function SingleBoyTarti() {
  const IMAGES = [
    {
      id: 1,
      src: process.env.PUBLIC_URL + "/img/Gallery/tarti-1.jpeg",
      caption: "BOY TARTI",
      size: "200-200",
      class: "one-third",
    },
    {
      id: 2,
      src: process.env.PUBLIC_URL + "/img/Gallery/tarti-2.jpeg",
      caption: "BOY TARTI",
      size: "200-200",
      class: "one-third",
    },
    {
      id: 3,
      src: process.env.PUBLIC_URL + "/img/Gallery/tarti-3.jpeg",
      caption: "BOY TARTI",
      size: "200-200",
      class: "two-third",
    },
    {
      id: 4,
      src: process.env.PUBLIC_URL + "/img/Gallery/tarti-4.jpeg",
      caption: "BOY TARTI",
      size: "200-200",
      class: "one-third",
    },
    {
      id: 5,
      src: process.env.PUBLIC_URL + "/img/Gallery/tarti-5.jpeg",
      caption: "BOY TARTI",
      size: "200-200",
      class: "two-third",
    },
    {
      id: 6,
      src: process.env.PUBLIC_URL + "/img/Gallery/tarti-6.jpeg",
      caption: "BOY TARTI",
      size: "200-200",
      class: "one-third",
    },
  ];
  return (
    <div>
      <Banner title={"BOY TARTI"} />
      <div id="performanz-trainer">
        <div class="container">
          <div class="row animate-box fadeInUp animated-fast">
            <div class="col-md-12 col-md-offset-2 text-center performanz-heading">
              <h2>Performanz Boy Tartı</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="row">
                <div
                  style={{ marginTop: "-30px" }}
                  class="col-lg-8  animate-box fadeInUp animated-fast"
                >
                  <h3> Boy Uzunluğu Ve Kilo Ölçer </h3>
                  <br />
                  <div className="my_container">
                    <ul>
                      <li>
                        Boy ve kilo ölçümü bir arada yapabilen cihazdır.
                        Bluetooth ile boy ve kilo değerlerini android sistemli
                        bir tablete/ telefona aktarım yapar.
                      </li>
                      <li>
                        Kolay kurulum ve kolay taşınabilir özelliktedir. Hem
                        Manuel olarak hem de bluetooth yardımı ile otomatik
                        olarak hem boy hem kilo ölçümü yapılabilir.
                      </li>
                      <li>
                        Kilo ölçeri en az 195 kg kapasiteli, en fazla 100 gr
                        hassasiyetindedir.
                      </li>
                      <li>
                        Boy uzunluk ölçer en az 600 mm ve en çok 2000 mm
                        arasında 1 mm'lik artışlarla ölçüm yapabilir.
                      </li>
                    </ul>
                  </div>
                  <br />

                  <p></p>
                  <p></p>
                  <p className="mb-5">
                    {" "}
                    Sistem özellikleri ve gereksinimler için{" "}
                    <a
                      href="https://performa.nz/docs/BoyTarti_2021.pdf"
                      download=""
                    >
                      {" "}
                      tıklayınız.
                    </a>
                  </p>
                  {/* <p>
                    Uygulamamızı indirmek için
                    <a
                      href="https://performa.nz/files/apk/kilo_boy.apk"
                      download=""
                    >
                      {" "}
                      tıklayınız.
                    </a>
                  </p> */}

                  {/* <p>
                    Uygulamamızı indirmek için(YENİ!!)
                    <a
                      href="https://performa.nz/files/apk/boykilo14092022.apk"
                      download=""
                    >
                      {" "}
                      tıklayınız.
                    </a>
                  </p> */}
                </div>
                <div class="col-lg-4  mb-5  animate-box fadeInUp animated-fast">
                  <img
                    style={{ maxWidth: "400px" }}
                    src={process.env.PUBLIC_URL + "/img/Gallery/tarti-2.jpeg"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="performanz-started"
          class="performanz-bg"
          style={{
            backgroundImage: `url("${
              process.env.PUBLIC_URL + "/img/bg_img/img_bg_1.jpg"
            }")`,
          }}
        >
          <div class="overlay"></div>
          <div id="performanz-gallery">
            <div class="container">
              <div class="row">
                <div class="col-md-12 col-md-offset-2 text-center performanz-heading animate-box fadeInUp animated-fast">
                  <h2 style={{ color: "#fff" }}>PERFORMANZ BOY TARTI</h2>
                  <p>ÖLÇTÜĞÜNÜ GELİŞTİRİRSİN!</p>
                </div>
              </div>
            </div>

            <div class="container-fluid">
              <div class="row row-bottom-padded-md">
                <div class="col-md-12 image-popup">
                  <GalleryCom images={IMAGES} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleBoyTarti;
